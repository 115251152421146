@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');
:root {
  --primary: #36c690;
}

* {
  font-family: 'Kanit', sans-serif !important;
}
body {
  margin: 0;
  font-family: 'Kanit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Kanit', sans-serif;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}
